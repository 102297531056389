import { Form, Input, InputNumber, Select } from "antd";

export function CreateForm({ form, handleSubmit }) {
  return (
    <Form
      form={form}
      name="loginForm"
      onFinish={handleSubmit}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
    >
      <Form.Item
        name="name"
        label="Enter Name"
        rules={[
          {
            required: true,
            message: "Please add Name!",
          },
        ]}
      >
        <Input placeholder="Enter your Name" />
      </Form.Item>
      <Form.Item
        name="userId"
        label="Enter UserId"
        rules={[
          {
            required: true,
            message: "Please add UserId!",
          },
        ]}
      >
        <Input placeholder="Enter your UserId" />
      </Form.Item>
      <Form.Item
        name="username"
        label="Enter Username"
        rules={[
          {
            required: true,
            message: "Please add Username!",
          },
        ]}
      >
        <Input placeholder="Enter your Username" />
      </Form.Item>
      <Form.Item
        name="brokerToken"
        label="Enter Token"
        rules={[
          {
            required: true,
            message: "Please add Token!",
          },
        ]}
      >
        <Input placeholder="Enter your Token" />
      </Form.Item>

      <Form.Item
        name="maxProfit"
        label="Enter Max Profit"
        rules={[
          {
            required: true,
            message: "Please add Max Profit!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your Max Profit"
        />
      </Form.Item>

      <Form.Item
        name="maxLoss"
        label="Enter Max Loss"
        rules={[
          {
            required: true,
            message: "Please add Max Loss!",
          },
        ]}
      >
        <InputNumber
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your Max Loss"
        />
      </Form.Item>
      <Form.Item
        name="baseQuantity"
        label="Enter Base Quantity"
        rules={[
          {
            required: true,
            message: "Please add Base Quantity!",
          },
        ]}
      >
        <InputNumber
          min={0}
          style={{ width: "100%" }}
          max={Infinity}
          placeholder="Enter your Base Quantity"
        />
      </Form.Item>
      <Form.Item
        name={"isLive"}
        label="Select status"
        rules={[
          {
            required: true,
            message: "Live is required",
          },
        ]}
      >
        <Select placeholder="Is User is Online">
          <Select.Option value={true}>Live</Select.Option>
          <Select.Option value={false}>Offline</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={"activeUser"}
        label="Select Active status"
        rules={[
          {
            required: true,
            message: "Active Status is required",
          },
        ]}
      >
        <Select placeholder="Is User is Active">
          <Select.Option value={true}>Active</Select.Option>
          <Select.Option value={false}>Not Active</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name={"_id"} style={{ height: "1px" }}></Form.Item>
    </Form>
  );
}

export default CreateForm;
