import { useState, useEffect } from "react";
import StockPercentageChart from "./StackedBar";
import { Table } from "antd";
import Heatmap from "../heatmap";
import Pulse from "../pulse";
import "./style.css";
import C from "./constant";
function Chart() {
  const [current, setCurrent] = useState("Tick");
  const [tickData, setTickDat] = useState([]);

  // Define a function to render the iframe for external sites
  const renderIframe = (src, title) => (
    <div className="sensibull">
      <iframe src={src} title={title}></iframe>
    </div>
  );
  useEffect(() => {
    const ws = new WebSocket("wss://market-price-egb2.onrender.com");

    // Handle WebSocket connection open
    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    // Handle incoming messages
    ws.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(event.data); // Assuming the data is JSON
        if (parsedData?.length) {
          setTickDat(parsedData);
        }
      } catch (err) {
        console.error("Error parsing WebSocket data:", err);
      }
    };

    // Handle WebSocket errors
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // Handle WebSocket closure
    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Clean up the WebSocket connection on unmount
    return () => {
      ws.close();
    };
  }, []);
  const getChange = (number) => {
    if (!number) return "-";
    const fixedNumber = parseFloat(
      number.toString().split(".")[0] +
        "." +
        number.toString().split(".")[1].slice(0, 2)
    );
    return fixedNumber;
  };

  const getNiftyPrice = () => {
    return (
      <>
        NIFTY50:{" "}
        <span className={tickData[0]?.change < 0 ? "loss" : "profit"}>
          {tickData[0]?.ltp?.toLocaleString() || "-"} &nbsp;{" "}
          {getChange(tickData[0]?.change) > 0 ? "+" : ""}
          {getChange((tickData[0]?.ltp * tickData[0]?.change) / 100) || "-"}
          &nbsp;( {getChange(tickData[0]?.change) > 0 ? "+" : ""}
          {getChange(tickData[0]?.change) || "-"}%)
        </span>
        <br />
        <span>
          &nbsp;O: {tickData[0]?.data?.ohlc?.open?.toLocaleString() || "-"}{" "}
        </span>
        <span>
          &nbsp;H: {tickData[0]?.data?.ohlc?.high?.toLocaleString() || "-"}{" "}
        </span>
        <span>
          &nbsp;L: {tickData[0]?.data?.ohlc?.low?.toLocaleString() || "-"}{" "}
        </span>
        <span>
          &nbsp;C: {tickData[0]?.data?.ohlc?.close?.toLocaleString() || "-"}{" "}
        </span>
      </>
    );
  };
  const Tick = () => {
    const rowStyle = { height: "30px" }; // Adjust the row height
    const cellStyle = { padding: "4px 8px" }; // Adjust the cell padding
    return (
      <>
        <Table
          rowKey={"_id"}
          className="table tickTable"
          title={getNiftyPrice}
          dataSource={tickData?.slice(1)}
          columns={C.column}
          pagination={false}
          style={{ maxHeight: "300px", overflow: "auto" }} // Adjust maxHeight as needed
          components={{
            body: {
              row: (props) => (
                <tr {...props} style={{ ...props.style, ...rowStyle }}>
                  {props.children}
                </tr>
              ),
              cell: (props) => (
                <td {...props} style={{ ...props.style, ...cellStyle }}>
                  {props.children}
                </td>
              ),
            },
          }}
        />
      </>
    );
  };

  const renderCharts = () => {
    switch (current) {
      case "Options":
        return (
          <>
            <StockPercentageChart indexType={"NIFTY"} />
            {/* <StockPercentageChart indexType={"BANKNIFTY"} /> */}
          </>
        );
      case "Groww":
        return (
          <div className="groww">
            {renderIframe(
              "https://groww.in/charts/indices/nifty",
              "Groww Nifty"
            )}
            {renderIframe(
              "https://groww.in/charts/indices/nifty-bank",
              "Groww Nifty Bank"
            )}
          </div>
        );
      case "Sensibull":
        return (
          <div className="sensibull">
            <iframe
              src="https://web.sensibull.com/open-interest/oi-change-vs-strike?tradingsymbol=NIFTY"
              title="Sensibull1"
            ></iframe>
          </div>
        );
      case "Technicals":
        return <Heatmap />;
      case "Pulse":
        return <Pulse />;
      case "Tick":
        return <Tick />;
      case "Twitter":
        return renderIframe("https://x.com/", "Twitter");
      case "ChatGPT":
        return renderIframe("https://chatgpt.com/", "ChatGPT");
      case "Onrender":
        return renderIframe(
          "https://dashboard.render.com/project/prj-crr5ocd6l47c73cdgv1g",
          "Onrender"
        );
      default:
        return null;
    }
  };

  return (
    <div className="user-container">
      <div className="user-sub-container">
        <div className="order-sub-container">
          <div className="users-list">
            {[
              "Tick",
              "Options",
              "Groww",
              "Sensibull",
              "Technicals",
              "Pulse",
            ].map((data) => (
              <button
                key={data}
                className={`custom ${current === data ? "activeButton" : ""}`}
                onClick={() => setCurrent(data)}
              >
                {data}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="chart_data">{renderCharts()}</div>
    </div>
  );
}

export default Chart;
