import { Tag } from "antd";

// eslint-disable-next-line
export default {
  column: [
    {
      title: "Sl",
      dataIndex: "sl",
      key: "name",
      render: (_id, _data, idx) => <span>{idx + 1}</span>,
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "LTP",
      dataIndex: "ltp",
      key: "ltp",
      render: (data) => {
        return data ? (
          <span className={data < 0 ? "loss" : "profit"}>
            {data >= 0 ? `+${data.toLocaleString()}` : data.toLocaleString()}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Volume",
      dataIndex: "volume_traded",
      key: "volume_traded",
      render: (data) => {
        const abbreviateNumber = (num) => {
          if (num >= 1e6) {
            return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
          } else if (num >= 1e3) {
            return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
          }
          return num.toString();
        };

        return data ? (
          <span style={{ color: "#4988ee" }}>{abbreviateNumber(data)}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "TV",
      dataIndex: "tickVolume",
      key: "tickVolume",
      render: (data) => {
        const abbreviateNumber = (num) => {
          if (num >= 1e6) {
            return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
          } else if (num >= 1e3) {
            return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
          }
          return num.toString();
        };

        return (
          <span
            style={{
              color: "white",
              backgroundColor: data > 3000 ? "green" : null,
            }}
          >
            {data ? abbreviateNumber(data) : 0}
          </span>
        );
      },
    },
    {
      title: "LTV",
      dataIndex: "tickVolume",
      key: "tickVolume",
      render: (_d, full) => {
        const abbreviateNumber = (num) => {
          if (num >= 1e6) {
            return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
          } else if (num >= 1e3) {
            return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
          }
          return num.toString();
        };

        return (
          <span style={{ color: "#4988ee" }}>
            {full?.data
              ? abbreviateNumber(full?.data?.last_traded_quantity)
              : 0}
          </span>
        );
      },
    },
    {
      title: "VWAP",
      dataIndex: "vwap",
      key: "vwap",
      render: (data) => {
        return data ? (
          <span style={{ color: "#4988ee" }}>
            {data >= 0 ? `+${data.toLocaleString()}` : data.toLocaleString()}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Diff",
      dataIndex: "diff",
      key: "diff",
      render: (data) => {
        return data ? (
          <span className={data < 0 ? "loss" : "profit"}>
            {data >= 0 ? `+${data?.toFixed(2)}` : data?.toFixed(2)}
          </span>
        ) : (
          "-"
        );
      },
    },

    {
      title: "Signal",
      dataIndex: "signal",
      key: "signal",
      render: (data) => (
        <Tag
          style={{
            width: "45px",
            textAlign: "center",
            background: data === "SELL" ? "red" : "green",
          }}
          color={"white"}
        >
          {data}
        </Tag>
      ),
    },
  ],
};
