import { Tag, Button, Popconfirm } from "antd";

// eslint-disable-next-line
export default {
  column: (handleEdit, handleDelete) => [
    {
      title: "Sl",
      dataIndex: "sl",
      key: "name",
      render: (_id, _data, idx) => <span>{idx + 1}</span>,
      fixed: "left",
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      fixed: "left",
      render: (data) => (
        <Tag
          style={{ width: "40px", textAlign: "center" }}
          color={data === "BUY" ? "green" : "red"}
        >
          {data}
        </Tag>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
      fixed: "left",
    },
    {
      title: "Trading Symbol",
      dataIndex: "tradingsymbol",
      key: "tradingsymbol",
      fixed: "left",
      render: (data) => (data ? data : "-"),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      fixed: "left",
      width: 70,
    },
    {
      title: "Expiry",
      dataIndex: "expiry",
      key: "expiry",
      width: 70,
    },
    {
      title: "K.Var",
      dataIndex: "kVariance",
      key: "kVariance",
      render: (data) => data || "-",
      width: 70,
    },
    {
      title: "B.Var",
      dataIndex: "variance",
      key: "variance",
      width: 70,
    },
    {
      title: "B.Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 70,
    },
    {
      title: "S.Var",
      dataIndex: "variance",
      key: "variance",
      width: 70,
    },
    {
      title: "S.Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 70,
    },

    {
      title: "Target",
      dataIndex: "target",
      key: "target",
      width: 70,
    },
    {
      title: "SL",
      dataIndex: "sl",
      key: "sl",
      width: 70,
    },
    {
      title: "S.Price",
      dataIndex: "shiftPrice",
      key: "shiftPrice",
      render: (data) => data || "-",
      width: 70,
    },
    {
      title: "Strat",
      dataIndex: "stratergy",
      key: "stratergy",
      fixed: "right",
      render: (data) => (
        <Tag style={{ width: "40px", textAlign: "center" }} color={"blue"}>
          {data}
        </Tag>
      ),
    },
    {
      title: "Live",
      dataIndex: "isLiveToTrade",
      key: "isLiveToTrade",
      fixed: "right",
      render: (data) => (
        <Tag
          style={{ width: "55px", textAlign: "center" }}
          color={data ? "green" : "red"}
        >
          {data ? "Live" : "Offline"}
        </Tag>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      render: (data) => (
        <Tag
          style={{ textAlign: "center" }}
          color={data !== "REJECTED" ? "green" : "red"}
        >
          {data}
        </Tag>
      ),
    },
    {
      title: "-",
      dataIndex: "_id",
      key: "_id",
      fixed: "right",
      render: (data) => (
        <Button type="primary" onClick={() => handleEdit(data)}>
          Edit
        </Button>
      ),
    },
    {
      title: "-",
      dataIndex: "_id",
      key: "_id",
      fixed: "right",
      render: (data) => (
        <Popconfirm
          title={"Deleting Order"}
          description={"Are you Sure to Delete?"}
          onConfirm={() => handleDelete(data)}
          okText="Yes"
        >
          <Button type="primary" danger>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ],
};
